export default class Builder {
    constructor(o) {
        this.type = 'unknown';
        this.col = document.createElement('div');
        this.card = document.createElement('div');
        this.cardBody = document.createElement('div');
        this.cardTitle = document.createElement('div');
        this.locationBox = document.createElement('div');
        this.dateBox = document.createElement('div');
        this.image = new Image();
        this.imageContainer = document.createElement('div');
        this.link = document.createElement('a');
        this.col.dataset.id = o.id;
        this.col.dataset.city = o.city?.id;
        this.href = o.link;
        this.city = '';
        this.date = '';
        this.title = '';
        this.categories = '';
        this.city_id = '';
        this.dates = '';
        this.latitude = '';
        this.longitude = '';

        this.addFields(o);

    }


    build() {
        this.col.classList.add('grid-item');

        this.card.classList.add('card', 'event-teaser');
        this.cardBody.classList.add('card-body');
        this.imageContainer.classList.add('card-img-custom');
        this.cardTitle.classList.add('card-title');
        this.cardTitle.innerText = this.title;
        this.dateBox.innerText = this.date;
        this.dateBox.classList.add('event-teaser-date');
        this.locationBox.innerText = this.city;
        this.cardBody.insertAdjacentElement('beforeend', this.dateBox);
        this.cardBody.insertAdjacentElement('beforeend', this.cardTitle);
        if (this.city) {
            this.cardBody.insertAdjacentElement('beforeend', this.locationBox);
        }
        this.card.insertAdjacentElement('beforeend', this.imageContainer);
        this.imageContainer.insertAdjacentElement('beforeend', this.image);
        this.card.insertAdjacentElement('beforeend', this.cardBody);
        this.col.insertAdjacentElement('beforeend', this.card);
        this.col.dataset.source = this.type;
        if (this.href) {
            this.link.href = this.href;
            this.link.classList.add('stretched-link');
            this.cardBody.insertAdjacentElement('afterbegin', this.link);
        }
        this.col.dataset.category = this.categories;
        this.col.dataset.city = this.city_id;
        this.col.dataset.dates = this.dates;

        return this.col;
    }

    addFields(o) {

        this.city = o.city ? o.city.name : null;
        this.city_id = o.city?.id;
        this.categories = o.categories?.map((i) => i.id).join(',');
        this.title = o.name;
        if (o.thumbnail)
            this.image.src = o.thumbnail;
        else
            this.image.src = 'https://loremflickr.com/262/196?v=' + o.id;
        if (o.location) {
            this.col.dataset.latitude = o.location.latitude;
            this.col.dataset.longitude = o.location.longitude;
        }
        this.col.dataset.hide = o.hide_on_startpage === true ? 'true' : 'false';
        this.col.dataset.sticky = o.sticky === true ? 'true' : 'false';
        this.col.dataset.freePortrait = o.free_portrait === true ? 'true' : 'false';
        this.col.dataset.hightlight = o.highlight === true ? 'true' : 'false';
        this.col.dataset.title = o.name;
        this.col.dataset.cityName = o.city?.name;
        this.col.dataset.cityPlz = o.city?.plz;
        if (o.highlight === true) {
            this.col.classList.add('highlight');
        }
        if (o.categories && o.categories.length > 0 && o.categories[0].color) {
            this.cardBody.style.backgroundColor = this.hexToRGB(o.categories[0].color, 0.1);
            this.cardBody.style.color = this.hexToRGB(o.categories[0].color);
        }
    }

    hexToRGB(hex, alpha = 1) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16),
            a = parseInt(hex.slice(7, 9), 16) / 255;
        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha * a + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }
}
