import map_api from "./googleMapsApi";
import {Promise} from "es6-promise";
import CategoryList from "../../models/category/category-list";
import CategoryItem from "../../models/category/category-item";
import InfoWindow = google.maps.InfoWindow;
import Polygon = google.maps.Polygon;

export default class Map {
    public markers: any;
    private readonly map_element: HTMLElement;
    private map: google.maps.Map<HTMLElement>;
    private bounds: google.maps.LatLngBounds;
    private readonly promise: Promise<this>;
    private _categories: CategoryList;
    private infoWindows: any;
    private infoWindow: InfoWindow;
    private polygon: Polygon;

    constructor() {
        this.map_element = document.getElementById('map');
        if (!this.map_element) {
            return;
        }
        this.promise = new Promise((resolve) => {
            this.map = null;
            this.markers = {};
            this.infoWindows = {};
            this.bounds = null;
            this.infoWindow = null;
            this._categories = new CategoryList();
            map_api.load().then(() => {
                this.initMap();
                resolve(this);
            });
        });
    }

    load(): Promise<this> {
        if (!this.promise) {
            return new Promise((resolve, reject) => {
                reject('map not loaded');
            })
        }
        return this.promise;
    }

    initMap() {
        if (this.map) {
            return;
        }
        this.bounds = new google.maps.LatLngBounds();
        this.bounds.extend({lat: 47.4, lng: 9.5833});

        this.map = new google.maps.Map(this.map_element, {
            center: {lat: 47.4, lng: 9.5833},
            zoom: 3,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                // position: google.maps.ControlPosition.TOP_CENTER
            },
            zoomControl: true,
            scaleControl: true,
            streetViewControl: false,
            fullscreenControl: false
        });
        this.getRheintal();
    };

    getMarkerIcon(category) {
        const colors = [
            'yellow-dot',
            'blue-dot',
            'green-dot',
            'ltblue-dot',
            'orange-dot',
            'pink-dot',
            'purple-dot',
            'red-dot',
            'yellow',
            'blue',
            'green',
            'lightblue',
            'orange',
            'pink',
            'purple',
            'red',
        ];
        if (category.map_icon) {
            return {
                url: category.map_icon
            }
        }
        return {
            url: "https://maps.google.com/mapfiles/ms/icons/" + colors[category.id % colors.length] + ".png"

        }
    }

    hideMarker(id) {
        if (this.markers[id])
            this.markers[id].setMap(null);
    }

    showMarker(id) {
        if (this.markers[id]) {
            this.markers[id].setMap(this.map);
        }
    }

    addMarker(object, showEverywhere = false) {
        if (object.location && object.location.latitude && object.location.longitude && !object.canceled) {
            const position = new google.maps.LatLng(object.location.latitude, object.location.longitude);
            // console.log();
            if (!showEverywhere && !google.maps.geometry.poly.containsLocation(position, this.getRheintal())) {
                return;
            }
            if (!object.link) {
                return;
            }
            let icon = null;
            if (object.categories && object.categories.length > 0) {
                icon = this.getMarkerIcon(object.categories[0])
            }

            this.markers[object.id] =
                new google.maps.Marker({
                    position: position,
                    map: this.map,
                    icon: icon
                });

            this.infoWindows[object.id] = new google.maps.InfoWindow({
                content: `<a href='${object.link}'>${object.name}</a>`
            });

            this.markers[object.id].addListener('click', () => {
                if (this.infoWindow) {
                    this.infoWindow.close();
                }
                this.infoWindow = this.infoWindows[object.id];
                this.infoWindow.open(this.map, this.markers[object.id]);
            });
        }
    }

    centerMap() {
        this.bounds = new google.maps.LatLngBounds();

        let count = 0;
        for (let i in this.markers) {
            if (!this.markers.hasOwnProperty(i)) {
                continue
            }
            let marker = this.markers[i];
            if (!marker) {
                continue;
            }
            if (marker.getMap() === null) {
                continue;
            }
            count++;
            //extend the bounds to include each marker's position
            this.bounds.extend(marker.position);
        }
        if (count === 0) {
            this.bounds.extend({lat: 47.4, lng: 9.5833});
        }


        // this.markerCluster = new MarkerClusterer(this.map, this.activeMarkers(),
        //     {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
            let zoom = this.map.getZoom();
            if (zoom > 15) {
                zoom = (15);
            }
            if (count === 0) {
                zoom = (12);
            }
            this.map.setZoom(zoom);
        });
        this.map.fitBounds(this.bounds);
    }


    categories(): Promise<CategoryItem[]> {
        return this._categories.getAll();
    }

    getRheintal() {
        if (this.polygon) {
            return this.polygon;
        }
        const boundary = '9.5999268 47.4532674,9.6218995 47.4402653,9.593747 47.4258664,9.5140961 47.4035635,9.4962434 47.3589293,9.5010499 47.3319445,9.4804505 47.298427,9.4577912 47.2686157,9.5381287 47.2737407,9.5518616 47.2867839,9.5559815 47.2965642,9.5614747 47.3016865,9.5704011 47.3072739,9.5793275 47.310533,9.595807 47.3258943,9.5992402 47.3412512,9.6225861 47.3649757,9.6576051 47.3682312,9.6747712 47.3789263,9.6761445 47.3896193,9.6644715 47.3998454,9.6534852 47.4072813,9.6486787 47.4254018,9.6445588 47.4393365,9.6576051 47.4476955,9.659665 47.454196,9.6370057 47.4565174,9.6191529 47.4588388,9.6143464 47.4690514,9.6061066 47.4713722,9.6047333 47.4634811,9.595807 47.4634811,9.5999268 47.4532674';
        const latlongs = boundary.split(",");
        const rheintal_bounds: google.maps.LatLng[] = [];

        for (let i = 0; i < latlongs.length; i++) {
            let latlong;
            latlong = latlongs[i].trim().split(" ");
            rheintal_bounds[i] = new google.maps.LatLng(latlong[1], latlong[0]);
        }

        this.polygon = new google.maps.Polygon({
            paths: rheintal_bounds,
            strokeColor: "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: 'Red',
            fillOpacity: 0.4,
            // map: this.map
        });
        return this.polygon;
    }
}
