export abstract class AbstractItem {
    id;
    name;
    map_icon;
    icon;
    color;
    type: string;
    private portraitCount: number;

    protected constructor(object) {
        this.id = object.id;
        this.map_icon = object.map_icon;
        this.name = object.name;
        this.icon = object.icon;
        this.color = object.color;
        this.portraitCount = object.portrait_count;
        this.type = ''
    }

    html() {
        if (this.portraitCount === 0) {
            return;
        }
        const span = document.createElement('span') as HTMLSpanElement;
        span.innerHTML = `${this.name} (${this.portraitCount})`;
        span.style.color = this.color;
        span.dataset.id = this.id;
        span.dataset.type = this.type;
        span.setAttribute('role', 'rowgroup');
        if (this.icon) {
            const logo = document.createElement('img') as HTMLImageElement;
            logo.src = this.icon;
            span.insertAdjacentElement('afterbegin', logo);
        }
        return span;
    }

    getLocalizedField(item, name) {
        const preferred_languages = ['de', 'de_CH', 'en'];
        if (!item.localizedfields) {
            return null;
        }
        for (let lang of preferred_languages) {
            if (item.localizedfields[lang] && item.localizedfields[lang][name]) {
                return item.localizedfields[lang][name]
            }
        }
    }
}
