import {AbstractItem} from "../abstract-list/abstract-item";

export default class CategoryItem extends AbstractItem {
    id;
    name;
    map_icon;
    icon;
    color;

    constructor(object) {
        super(object);
        this.type = 'category';
        this.name = this.getLocalizedField(object, 'Name');
    }

}
