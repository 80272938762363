import BuilderEvent from "./list/builderEvent";
import BuilderPortrait from "./list/builderPortrait";
import Map from "./list/map";
import {AbstractItem} from "../models/abstract-list/abstract-item";

const imagesloaded = require('imagesloaded/imagesloaded.pkgd.min');
const Isotope = require('isotope-layout');
require('isotope-fit-columns');
require('isotope-cells-by-row');
// add cellsByRow layout mode
require('isotope-packery');
require('air-datepicker/dist/js/datepicker.min');
require('air-datepicker/dist/js/i18n/datepicker.de');
const InfiniteScroll = require('infinite-scroll');
export default class ItemList {
    public filters: { city: any[]; source: any[]; category: any[] };
    private readonly eventsContainer: HTMLElement;
    private itemStore: Set<any>;
    private cardStore: Set<any>;
    private allDates: Set<any>;
    private limit: number;
    private pageIndex: number;
    private filterDate: string;
    private displayMode: string;
    private map: Map;
    private infiniteScroll: any;
    private $picker: any;
    private iso: any;

    constructor() {
        this.eventsContainer = document.getElementById('event-list');
        if (!this.eventsContainer) {
            return;
        }

        this.itemStore = new Set();
        this.cardStore = new Set();

        this.allDates = new Set();

        this.limit = 100;
        this.pageIndex = 0;

        this.filterDate = '';
        this.filters = {
            city: [],
            category: [],
            source: []
        };

        this.iso = new Isotope(this.eventsContainer, {
            itemSelector: '.grid-item',
            layoutMode: 'packery',
            percentPosition: true,
            getSortData: {
                sticky: '[data-sticky]',
                portrait: '[data-free-portrait]',
                time: '[data-start-date]', // value of attribute
                modification: '[data-modification-date]', // value of attribute
                portrait_name: '[data-portrait-name]'
            },
            sortBy: ['sticky', 'portrait', 'portrait_name', 'modification', 'time'],
            sortAscending: {
                modification: false,
                sticky: false,
                portrait: true,
                time: true,
            }
        });

        let map = new Map();
        map.load().then((map) => {
            this.map = map;
            this.parseHash();
            this.setPredefinedFilters();
            this.infiniteScroll = new InfiniteScroll(this.eventsContainer, {
                path: () => {
                    return this.getPath();
                },
                append: false,
                responseType: 'text',
                // outlayer: this.iso,
                history: false,
                status: '.page-load-status'
            });

            this.initListeners();
            this.applyFilters();
            this.initDatePicker();
            // this.loadItems();
        }).catch(() => {
        });


    }

    getPath(limit = this.limit) {
        const cities = this.filters.city.join(',');
        const categories = this.filters.category.join(',');
        return "/api/v1/"
            + this.displayMode
            + ".json?page=" + this.pageIndex
            + "&limit=" + limit
            + "&city=" + cities
            + "&category=" + categories
            + "&date=" + this.filterDate
            + "&loadedItems=" + btoa(Array.from(this.itemStore).join(','))
            + ("&searchTerm=" + this.filters['search'] ?? '')
            ;
    }

    appendItems(items = []) {
        for (let item of items) {
            if (this.itemStore.has(item.id)) {
                continue;
            }
            if (item.dates && item.canceled) {
                for (let d of item.dates) {
                    this.allDates.add(d);
                }
            }
            if (this.map)
                this.map.addMarker(item);
            this.itemStore.add(item.id);
            let card = this.buildEventCard(item);
            this.cardStore.add(card);
            this.iso.insert(card);
            imagesloaded(card, () => {
                this.iso.layout();
            })
        }
        if (this.map)
            this.map.centerMap();

    }

    loadItems() {
        this.pageIndex = 0;
        fetch(this.getPath(), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((response) => {
            this.appendItems(response.data);
        })
    }

    buildEventCard(o) {
        let b;
        if (o.hasOwnProperty('free_portrait')) {
            b = new BuilderPortrait(o);
            return b.build();
        }
        b = new BuilderEvent(o);
        return b.build();
    }

    visibleLength() {
        return (1 + this.pageIndex) * this.limit;
    }

    initListeners() {

        const categorySelectors = document.getElementsByClassName('event-filter');

        for (let categorySelectorGroup of Array.from(categorySelectors)) {
            categorySelectorGroup.addEventListener('click', (event) => {
                (event.currentTarget as HTMLElement).blur();
                (event.target as HTMLElement).blur();
                if ((event.currentTarget as HTMLElement).dataset.displayValue) {
                    this.setStringFilter(
                        (event.currentTarget as HTMLElement).dataset.displayValue,
                        FILTER.displayMode,
                        +(event.currentTarget as HTMLElement).dataset.displayType);

                    return;
                }

                if ((event.target as HTMLElement).dataset.link) {
                    this.setStringFilter((event.target as HTMLElement).dataset.link, FILTER.displayMode);
                    return;
                }
                if ((event.target as HTMLElement).dataset.key) {
                    if ((event.target as HTMLElement).dataset.type === 'city') {
                        this.toggleStringFilter((event.target as HTMLElement).dataset.key, FILTER.city);
                        return;
                    }
                    if ((event.target as HTMLElement).dataset.type === 'category') {
                        this.toggleStringFilter((event.target as HTMLElement).dataset.key, FILTER.category);
                        return;
                    }
                }
                // this.toggleFilter(event.target);
                this.applyFilters();
                document.getElementById('main-content').scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            })
        }

        this.infiniteScroll.on('load', (response) => {
            console.log('loaded by scroll');
            let data = JSON.parse(response).data;
            this.pageIndex++;
            if (data.length === 0 && this.visibleLength() < this.itemStore.size) {
                return;
            }
            if (data.length === 0) {
                this.infiniteScroll.options.loadOnScroll = false;
                this.pageIndex = 0;
                return;
            }
            this.appendItems(data);
        });
        const searchElement = document.getElementById('text-search') as HTMLInputElement;
        const debouncedKeyPress = debounce(() => this.setStringFilter(searchElement.value ?? '', FILTER.searchTerm), 600);
        searchElement.addEventListener('keyup', debouncedKeyPress)
        window.addEventListener('hashchange', () => {
            this.parseHash();
            this.applyFilters();
        });
    }

    initDatePicker() {
        this.$picker = $('#inline_picker');
        fetch('/api/v1/dates.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((response) => {
            this.$picker.datepicker({
                'language': 'de',
                'onSelect': (formattedDate, d) => {
                    console.debug('click event')
                    // this.setStringFilter(this.formatDate(d), FILTER.date);
                    this.setStringFilter(d ? this.formatDate(d) : '', FILTER.date);
                    // if (!d) {
                    //     this.filterDate = '';
                    //     this.setStringFilter(null, FILTER.date);
                    // } else {
                    //     // this.filterDate = this.formatDate(d);
                    //
                    // }
                    // this.filter();
                },
                onRenderCell: (date, cellType) => {
                    let currentDate = this.formatDate(date);
                    // Add extra element, if `eventDates` contains `currentDate`
                    if (cellType === 'day' && response.data.indexOf(currentDate) > -1) {
                        return {
                            html: date.getDate() + '<span class="dp-note"/>'
                        }
                    } else if (cellType === 'day') {
                        return {
                            disabled: true
                        }
                    }
                },
            });
            if (this.filterDate) {
                this.$picker.data("datepicker").selectDate(new Date(this.filterDate));
            }
        })
    }

    formatDate(d) {
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    toggleFilterId(type: string | AbstractItem[], value: number = null, wait = false) {
        if (typeof type === 'object') {
            for (let item of type) {
                this.toggleFilterId(item.type, item.id, true);
            }
        }
        if (typeof type === 'string') {
            if (this.filters[type].includes(value)) {
                this.filters[type] = this.filters[type].filter((item) => {
                    return item !== value
                });
            } else {
                this.filters[type].push(value);
            }
        }
        if (!wait) {
            this.applyFilters();
        }

    }

    applyFilters() {
        console.log('applying filters');
        this.loadItems();
        let i = 0;
        this.iso.arrange({
            filter: (itemElem) => {
                let id = +itemElem.dataset.id;
                let source = true;
                if (!this.filterBySource(itemElem)) {
                    source = false;
                }
                this.map.hideMarker(id);
                if (!this.filterByHideOnStartpage(itemElem)) {
                    return false;
                }
                if (!(this.filterByCity(itemElem) || this.filterBy(itemElem, 'city', 'alternativeCities'))) {
                    return false;
                }

                if (!this.filterByCategory(itemElem)) {
                    return false;
                }
                if (itemElem.dataset.dates) {
                    if (!this.filterByDate(itemElem)) {
                        return false;
                    }
                }
                if (!this.filterBySearch(itemElem)) {
                    return false;
                }
                this.map.showMarker(id);

                i++;

                return source;

            }
        });
        this.map.centerMap();
    }

    filterBySource(itemElem) {
        return this.filterBy(itemElem, 'source');
    }

    filterByDate(itemElem) {
        if (!this.filterDate) {
            return true;
        }
        const dates = itemElem.dataset.dates.split(',');

        return dates.includes(this.filterDate);
    }

    filterByCategory(itemElem) {
        return this.filterBy(itemElem, 'category');
    }

    filterByCity(itemElem) {
        return this.filterBy(itemElem, 'city');
    }

    filterBy(itemElem, filterName, dataSetName = filterName) {
        if (this.filters[filterName].length === 0) {
            return true;
        }
        const arr = itemElem.dataset[dataSetName].split(",");
        for (let number of arr) {
            number = parseInt(number);
            if (this.filters[filterName].includes(number)) {
                return true;
            }
        }
        return false;
    }

    setPredefinedFilters() {
        const url = new URL(window.location.href);
        const preSelectedCategoryId = url.searchParams.get("cat_id")?.split(',') ?? [];
        const preSelectedCityId = url.searchParams.get("city_id")?.split(',') ?? [];
        const limit = url.searchParams.get("limit");
        let hashParts = this.getHashParts();
        for (const cityId of preSelectedCityId) {
            const preSelectedCityField = document.querySelectorAll('[data-type="city"][data-value="' + cityId + '"]');
            if (preSelectedCityField.length === 1) {
                hashParts = this.toggleStringFilter((preSelectedCityField[0] as HTMLLIElement).dataset.key, FILTER.city, true, hashParts)
            }
        }

        for (const categoryId of preSelectedCategoryId) {
            const preSelectedCategoryField = document.querySelectorAll('[data-type="category"][data-value="' + categoryId + '"]');
            if (preSelectedCategoryField.length === 1) {
                hashParts = this.toggleStringFilter((preSelectedCategoryField[0] as HTMLLIElement).dataset.key, FILTER.category, true, hashParts)
            }
        }

        // const preSelectedSourceField = document.querySelectorAll('[data-type="source"][data-value="1"]');
        if (limit) {
            this.limit = parseInt(limit);
        }
        // location.search = '';
        location.hash = hashParts.join('/');
    }

    filterByHideOnStartpage(itemElem) {
        if (this.filters['city'].length > 0) {
            return true;
        }
        return itemElem.dataset.hide !== 'true';

    }

    clearFilter(name) {
        if (name in this.filters)
            this.filters[name] = [];
    }

    clearAllFilters() {
        this.filters = {
            city: [],
            source: [],
            category: []
        };

    }

    addLink(addLink) {
        this.iso.items.forEach((item) => {
            let a = item.element.getElementsByTagName('a')[0];
            if (!a) {
                return;
            }
            if (!item.a) {
                item.a = a.href;
            }
            a.href = item.a + addLink;
        })
    }

    setDisplayMode(mode: string) {
        const modes = mode.split('-');
        const body = document.querySelector('body');
        this.displayMode = mode.split('-')[0] === 'veranstalter' ? 'portraits' : 'events';
        if (mode.split('-')[1] === 'list') {
            this.iso.arrange({'layoutMode': 'vertical'});
            this.eventsContainer.dataset.layoutMode = 'vertical';
            body.dataset.layoutMode = 'vertical';
        } else {
            body.dataset.layoutMode = 'vertical';
            this.eventsContainer.dataset.layoutMode = 'packery';
            this.iso.arrange({'layoutMode': 'packery'});
        }
        body.dataset.displayMode = this.displayMode;
        const mNo = this.displayMode === 'portraits' ? 2 : 1;
        document.querySelectorAll('#display-mode-buttons > button').forEach((l: HTMLElement) => {
            l.classList.remove('btn-primary', 'btn-secondary');
            if (modes.includes(l.dataset.displayValue) || (l.dataset.displayValue === 'packed') && modes[1] == undefined) {
                l.classList.add('btn-primary');
            } else
                l.classList.add('btn-secondary');

        });
        document.querySelectorAll('.portraits-selectors > li').forEach((l) => {
            l.classList.remove('active');
            if ((l as HTMLLIElement).dataset.value === '' + mNo) {
                l.classList.add('active');
            }
        });
        this.filters['source'] = [mNo];
    }

    setCityFilter(cities: string[]) {
        const ids = [];
        document.querySelectorAll('.city-selectors > li').forEach((l) => {
            l.classList.remove('active');
            if (cities.includes((l as HTMLLIElement).dataset.key)) {
                l.classList.add('active');
                ids.push(+(l as HTMLLIElement).dataset.value);
            }
        });
        this.filters['city'] = ids;
    }

    setCategoryFilter(categories: string[]) {
        const ids = [];
        document.querySelectorAll('.category-selectors > li').forEach((l) => {
            l.classList.remove('active');
            if (categories.includes((l as HTMLLIElement).dataset.key)) {
                l.classList.add('active');
                ids.push(+(l as HTMLLIElement).dataset.value);
            }
        });
        this.filters['category'] = ids;
    }

    private parseHash() {
        const url = new URL(window.location.href);
        const hash = url.hash;
        if (hash.startsWith('#/')) {
            const hashParts = hash.split('/');
            this.setDisplayMode(hashParts[FILTER.displayMode]);
            this.setCityFilter(hashParts[FILTER.city]?.split(',') ?? []);
            this.setCategoryFilter(hashParts[FILTER.category]?.split(',') ?? []);
            this.setDateFilter(hashParts[FILTER.date] ?? '');
            this.setSearchFilter(hashParts[FILTER.searchTerm] ?? '');
        }

    }

    private getHashParts() {
        const url = new URL(window.location.href);
        const hash = url.hash;
        const hashParts = [];
        if (hash.startsWith('#/')) {
            hashParts.splice(0);
            hashParts.push(...hash.split('/'));
        }
        if (hashParts.length === 0) {
            hashParts.push('#');
        }
        if (hashParts.length === 1 || hashParts[1] === '') {
            hashParts[1] = ('events');
        }
        return hashParts;
    }

    private setStringFilter(key, position: FILTER, subPosition: number = null) {
        let hashParts = this.getHashParts();
        if (subPosition !== null) {
            let current = hashParts[position];
            current = current.split('-');
            current[subPosition] = key == 'packed' ? '' : decodeURI(key);
            current = this.cleanHashParts(current);
            hashParts[position] = current.join('-');
        } else {
            hashParts[position] = decodeURI(key);
        }
        hashParts = this.cleanHashParts(hashParts);
        location.href = hashParts.join('/');
    }

    private toggleStringFilter(key, position: FILTER, wait = false, hashParts = null) {
        hashParts = hashParts ?? this.getHashParts();
        const elements = [];
        if (hashParts[position]) {
            elements.push(...hashParts[position].split(','));
        }
        const pos = elements.indexOf(key);
        if (pos >= 0) {
            elements.splice(pos, 1);
        } else {
            elements.push(key);
        }
        hashParts[position] = elements.join(',');
        hashParts = this.cleanHashParts(hashParts);
        if (!wait) {
            location.href = hashParts.join('/');
        }
        return hashParts;
    }

    private cleanHashParts(hashParts: string[]) {
        if (hashParts[hashParts.length - 1] == '') {
            hashParts.pop();
            return this.cleanHashParts(hashParts);
        }
        return hashParts;
    }

    private setDateFilter(dates: string) {
        this.filterDate = dates;
        this.filters['dates'] = dates;
    }

    private setSearchFilter(s: string) {
        this.filters['search'] = decodeURI(s);
        (document.getElementById('text-search') as HTMLInputElement).value = decodeURI(s);
    }

    private filterBySearch(itemElem) {
        if (this.filters['search'].length === 0) {
            return true;
        }
        const searchString = this.filters['search'];
        if (itemElem.dataset.title.toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
            return true;
        }
        if (itemElem.dataset.cityName.toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
            return true;
        }
        if (itemElem.dataset.cityPlz.indexOf(searchString) > -1) {
            return true;
        }
        return false;
    }
}

enum FILTER {
    displayMode = 1,
    city = 2,
    category = 3,
    date,
    searchTerm
}

function debounce(callback, limit) {
    let timeout;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback();
        }, limit);
    };
}