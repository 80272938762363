import Builder from "./builder";

export default class BuilderPortrait extends Builder {
    constructor(props) {
        super(props);
        this.type = '2';
    }

    addFields(o) {
        super.addFields(o);
        this.date = o.categories ? o.categories.map((item) => {
            return this.getLocalizedField(item, 'Name')
        }).join(', ') : '';
        this.col.dataset.modificationDate = '' + new Date(o.modification_date).getTime();
        this.col.dataset.portraitName = o.name.replace(/[^0-9a-zA-ZüÜöÖäÄ]/g, "");
    }

    getLocalizedField(item, name) {
        const preferred_languages = ['de', 'de_CH', 'en'];
        if (!item.localizedfields) {
            return null;
        }
        for (let lang of preferred_languages) {
            if (item.localizedfields[lang] && item.localizedfields[lang][name]) {
                return item.localizedfields[lang][name]
            }
        }
    }

}
