import Builder from "./builder";

export default class BuilderEvent extends Builder {
    constructor(props) {
        super(props);
        this.type = '1';
    }

    addFields(o) {
        super.addFields(o);
        this.canceled = o.canceled;
        this.date = o.date;
        this.dates = o.dates;
        this.col.dataset.startDate = '' + new Date(o.start_date).getTime();
        this.col.dataset.alternativeCities = o.alternative_cities.map((a) => {
            return a.id
        }).join(',')
    }

}
