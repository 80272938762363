import {Promise} from "es6-promise";
import {AbstractItem} from "./abstract-item";

export default abstract class AbstractList {
    protected _items: AbstractItem[] = [];
    protected _allItems: AbstractItem[] = [];

    constructor(items: AbstractItem[] = []) {
        if (items.length > 0) {
            this._allItems = items;
        }
    }

    /**
     * @returns [CategoryItem]
     * @param items
     */
    getMultipleByIds(items: string[]): AbstractItem[] {
        const filtered = this._allItems.filter((item) => {
            const id = '' + item.id as string;
            return items.indexOf(id) > -1
        }).sort((a, b) => {
            if (items.indexOf("" + a.id) < items.indexOf("" + b.id)) {
                return -1;
            }
            if (items.indexOf("" + a.id) > items.indexOf("" + b.id)) {
                return 1;
            }
            return 0;
        });
        this._items = this._allItems;
        if (filtered.length > 0) {
            this._items = filtered;
        }
        return filtered;
        // return this;
    }

    getItems() {
        return this._items;
    }

    abstract getAll(): Promise<AbstractItem[]>;

    html(): Promise<HTMLElement[]> {
        return this.getAll().then((response) => {
            return response.map((item) => {
                return item.html();
            });
        });
    }

    draw(callback: (htmlElements: HTMLElement[]) => void) {
        this.html().then((result) => {
                callback(result);
            }
        )
    }

}

