import AbstractList from "../abstract-list/abstract-list";
import {Promise} from "es6-promise";
import CategoryItem from "./category-item";

export default class CategoryList extends AbstractList {

    getAll(): Promise<CategoryItem[]> {
        return new Promise((resolve) => {
            if (this._allItems.length > 0) {
                resolve(this._allItems);
            } else
                fetch("/api/v1/categories.json")
                    .then((response) => {
                        return (response.json())
                    })
                    .then((result) => {
                        const categories = result.data.map(p => {
                            return new CategoryItem(p)
                        });
                        this._allItems = categories;
                        resolve(categories);
                    });
        })
    }

}
