import 'bootstrap';
import 'isotope-layout';
import 'lightgallery'
import 'lg-video'
import 'lg-thumbnail'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch"
import ItemList from "./module/list";

// kick off the polyfill!
document.addEventListener('DOMContentLoaded', function () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.collapse').removeClass('show');
    }
    // new EventList();
    new ItemList();
    $('.event-gallery').lightGallery({
        selector: '.include-in-gallery'
    });
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
});


